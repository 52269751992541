import { ref } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'

import axiosClient from '@/helpers/axios'
import useAlertNotification from '@/services/notification/useAlert'
import useMairies from '@/services/super-admin/mairieService'

export default function useUsers() {
  const { errorToast, successToast } = useAlertNotification()
  const { getListeMairies } = useMairies()
  const user = ref([])
  const loader = ref(false)
  const userProcess = ref(false)
  const userSuccess = ref(false)
  const users = ref([])
  const usersAdministrateur = ref([])
  const usersSignataire = ref([])
  const usersRoleUtilisateur = ref([])
  const usersAgentVerificateur = ref([])
  const usersAgentsAndSigners = ref([])
  const responsable = ref([])
  const usersSignataires = ref([])
  const usersAgentsVerificateurs = ref([])
  const ADMINISTRATEUR = 'ADMINISTRATEUR'
  const SIGNATAIRE = 'SIGNATAIRE'
  const AGENT_VERIFICATEUR = 'AGENT VERIFICATEUR'
  const errors = ref('')
  const userAuth = ref([])
  if (store.state.authStore.token !== null) { userAuth.value = JSON.parse(store.state.authStore.user) }

  // Liste des Users
  const getListeUsers = async () => {
    try {
      loader.value = true
      const response = await axiosClient.get('/get-all-users')
      if (response.data.success === true) {
        loader.value = false
        users.value = response.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }
  const getAgentsAndSignersByMairie = async () => {
    try {
      loader.value = true
      const response = await axiosClient.get(`get-agents-and-signataires-by-mairie/${userAuth.value.mairie_id}`)
      if (response.data.success === true) {
        loader.value = false
        usersAgentsAndSigners.value = response.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }
  const getUsersByRole = async role => {
    try {
      loader.value = true
      const response = await axiosClient.get(`get-list-users-by-role/${role}`)
      if (response.data.success === true) {
        loader.value = false
        usersAgentsAndSigners.value = response.data.data
        if (role === 'ADMINISTRATEUR') usersAdministrateur.value = response.data.data
        else if (role === 'AGENT VERIFICATEUR') usersAgentVerificateur.value = response.data.data
        else if (role === 'SIGNATAIRE') usersSignataire.value = response.data.data
        else if (role === 'UTILISATEUR') usersRoleUtilisateur.value = response.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }
  const getOnlyUsersRoleUtilisateur = async () => {
    try {
      loader.value = true
      const response = await axiosClient.get('/get-only-users-role-utilisateur')
      if (response.data.success === true) {
        loader.value = false
        users.value = response.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }
  const getUsersByRoleByMairie = async (role, mairieSlug) => {
    try {
      loader.value = true
      const response = await axiosClient.get(`/get-users-by-role-by-mairie/${role}/${mairieSlug}`)
      if (response.data.success === true) {
        loader.value = false
        if (role === ADMINISTRATEUR) responsable.value = response.data.data
        else if (role === SIGNATAIRE) usersSignataires.value = response.data.data
        else if (role === AGENT_VERIFICATEUR) usersAgentsVerificateurs.value = response.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }
  const getUsersByRoleByMairieId = async role => {
    try {
      loader.value = true
      const response = await axiosClient.get(`/get-users-by-role-by-mairie-id/${role}/${userAuth.value.mairie_id}`)
      if (response.data.success === true) {
        loader.value = false
        if (role === ADMINISTRATEUR) { responsable.value = response.data.data } else if (role === SIGNATAIRE) usersSignataires.value = response.data.data
        else if (role === AGENT_VERIFICATEUR) usersAgentsVerificateurs.value = response.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }
  // Trouver un utilisateur par son id
  const findUserById = async id => {
    try {
      loader.value = true
      const response = await axiosClient.get(`/get-user-by-id/${id}`)
      if (response.data.success === true) {
        loader.value = false
        user.value = response.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }
  const findUserBySlug = async () => {
    try {
      loader.value = true
      const response = await axiosClient.get(`/get-user-by-slug/${router.currentRoute.params.slug}`)
      if (response.data.success === true) {
        loader.value = false
        user.value = response.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }
  const designateAdministrator = async data => {
    try {
      errors.value = ''
      userProcess.value = true
      const response = await axiosClient.patch(`/designate-admin/${data.user_id}`, data)
      if (response.data.success === true) {
        successToast('Enregistrement', response.data.message)
        userSuccess.value = true
        userProcess.value = false
        await getListeMairies()
        await getOnlyUsersRoleUtilisateur()
      }
    } catch (error) {
      userProcess.value = false
      userSuccess.value = false
      loader.value = false
      errors.value = ''
      if (error.response.status === 403) errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
      else if (error.response.status === 401) errorToast('Oups', error.response.data.message)
      else {
        errors.value = error.response.data.errors
        const responseErrors = error.response.data.errors
        Object.keys(responseErrors).forEach(key => {
          errorToast('Oups! Erreur', responseErrors[key][0])
        })
      }
    }
  }
  const addUserInTownHall = async data => {
    try {
      errors.value = ''
      userProcess.value = true
      const response = await axiosClient.post('/add-user-in-town-hall', data)
      if (response.data.success === true) {
        successToast('Enregistrement', response.data.message)
        userSuccess.value = true
        userProcess.value = false
        await getAgentsAndSignersByMairie()
        await getOnlyUsersRoleUtilisateur()
      }
    } catch (error) {
      userProcess.value = false
      userSuccess.value = false
      loader.value = false
      errors.value = ''
      if (error.response.status === 403) errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
      else if (error.response.status === 401) errorToast('Oups', error.response.data.message)
      else {
        errors.value = error.response.data.errors
        const responseErrors = error.response.data.errors
        Object.keys(responseErrors).forEach(key => {
          errorToast('Oups! Erreur', responseErrors[key][0])
        })
      }
    }
  }
  const withdrawFromTheTownHall = async id => {
    try {
      const data = {
        role: 'UTILISATEUR',
        mairie_id: null,
      }
      errors.value = ''
      userProcess.value = true
      const response = await axiosClient.patch(`/withdraw-from-town-hall/${id}`, data)
      if (response.data.success === true) {
        successToast('Suppression', response.data.message)
        userSuccess.value = true
        userProcess.value = false
        await getAgentsAndSignersByMairie()
        await getOnlyUsersRoleUtilisateur()
      }
    } catch (error) {
      userProcess.value = false
      userSuccess.value = false
      loader.value = false
      errors.value = ''
      if (error.response.status === 403) errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
      else if (error.response.status === 401) errorToast('Oups', error.response.data.message)
      else {
        errors.value = error.response.data.errors
        const responseErrors = error.response.data.errors
        Object.keys(responseErrors).forEach(key => {
          errorToast('Oups! Erreur', responseErrors[key][0])
        })
      }
    }
  }
  // Ajouter un user
  const createUser = async data => {
    errors.value = ''
    userProcess.value = true
    try {
      const response = await axiosClient.post('/create-user', data)
      if (response.data.success === true) {
        successToast(response.data.message)
        userSuccess.value = true
        userProcess.value = false
        const TOKEN = response.data.data.token
        store.state.authStore.token = TOKEN
        store.state.authStore.authenticated = true
        const userAuthentificate = await axiosClient.get('/user')
        user.value = userAuthentificate.data
        store.commit('setUser', userAuthentificate.data)
      } else {
        errorToast(response.data.message)
        userSuccess.value = false
        userProcess.value = false
      }
    } catch (error) {
      userProcess.value = false
      userSuccess.value = false
      errors.value = error.response.data.data.errors
      const responseErrors = error.response.data.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorToast(responseErrors[key][0])
      })
    }
  }
  return {
    errors,
    user,
    users,
    userProcess,
    usersRoleUtilisateur,
    usersAdministrateur,
    usersAgentVerificateur,
    usersSignataire,
    getUsersByRole,
    loader,
    getListeUsers,
    findUserById,
    findUserBySlug,
    getUsersByRoleByMairie,
    getOnlyUsersRoleUtilisateur,
    getAgentsAndSignersByMairie,
    addUserInTownHall,
    withdrawFromTheTownHall,
    usersSignataires,
    usersAgentsVerificateurs,
    getUsersByRoleByMairieId,
    designateAdministrator,
    usersAgentsAndSigners,
    responsable,
    createUser,
    userSuccess,
  }
}
